import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

// Fix for marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

// Custom component to update the map center
const UpdateMapCenter = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
  }, [center, map]);
  return null;
};

const Contact = ({
  address,
  workingHours,
  contactInfo,
  socialLinks,
  mapPosition,
}) => {
  return (
    <section className="p-5" id='contact'>
      <div className="row">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mb-4 mb-md-0">
          <MapContainer
            center={mapPosition}
            zoom={16}
            style={{ height: "500px", width: "100%" }}
            scrollWheelZoom={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={mapPosition}>
              <Popup>{address}</Popup>
            </Marker>
            <UpdateMapCenter center={mapPosition} />
          </MapContainer>
        </div>
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center text-md-left">
          <h4>Επικοινωνήστε μαζί μας</h4>
          <p>
            <strong>Διεύθυνση:</strong>{" "}
            <a
              href="https://www.google.com/maps/place/%CE%A3%CF%80%CE%B5%CF%83%CE%B9%CE%B1%CE%BB%CE%AF%CF%83%CF%84%CE%B5%CF%82+%CE%92%CE%B1%CF%83%CE%B9%CE%BB%CE%B5%CE%AF%CE%BF%CF%85/@39.375561,22.9532623,17z/data=!3m1!4b1!4m6!3m5!1s0x14a76df2d1cf50af:0xe0244c0da9f15807!8m2!3d39.3755569!4d22.9558372!16s%2Fg%2F11pzyv2kr8?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </p>
          <p>
            <strong>Ώρες λειτουργίας:</strong> {workingHours}
          </p>
          <p>
            <strong>Επικοινωνία:</strong> {contactInfo}
          </p>
          <div>
            {socialLinks.facebook && (
              <a
                href={socialLinks.facebook}
                className="mx-2"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size={30} />
              </a>
            )}
            {socialLinks.twitter && (
              <a
                href={socialLinks.twitter}
                className="mx-2"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size={30} />
              </a>
            )}
            {socialLinks.instagram && (
              <a
                href={socialLinks.instagram}
                className="mx-2"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={30} />
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
