import React from "react";
import Header from "./Header";
import Intro from "./Intro";
import About from "./About";
import Services from "./Services";
import Gallery from "./Gallery";
import Contact from "./Contact";
import Footer from "./Footer";

const Main = ({
  showHeader = true,
  headerProps,
  showIntro = true,
  introProps,
  showAbout = true,
  aboutProps,
  showServices = true,
  servicesProps,
  showGallery = true,
  galleryProps,
  showContact = true,
  contactProps,
  showFooter = true,
  footerProps,
  footerStyles,
}) => {
  return (
    <div>
      {showHeader && <Header {...headerProps} />}
      {showIntro && <Intro {...introProps} />}
      {showAbout && <About {...aboutProps} />}
      {showServices && <Services {...servicesProps} />}
      {showGallery && <Gallery {...galleryProps} />}
      {showContact && <Contact {...contactProps} />}
      {showFooter && <Footer {...footerProps} style={footerStyles} />}
    </div>
  );
};

export default Main;
