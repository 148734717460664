import React from "react";

const Services = ({ services }) => {
  return (
    <section className="p-5" id='services'>
      <div className="container">
        <h2 className="text-center mb-4">Οι Υπηρεσίες μας</h2>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-md-4 text-center">
              {service.image && (
                <img
                  src={require(`../${service.image}`)}
                  alt={service.title}
                  className="img-fluid mb-3"
                  loading="lazy"
                  style={{
                    width: "900px",
                    maxWidth: "100%",
                    height: "auto",
                    aspectRatio: "4/3",
                    objectFit: "cover",
                    verticalAlign: "bottom",
                  }}
                />
              )}
              <h4>{service.title}</h4>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
