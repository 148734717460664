import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = ({ address, workingHours, socialLinks, style }) => {
  return (
    <footer
      className="p-4"
      style={{
        backgroundColor: style.backgroundColor,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>Διεύθυνση</h5>
            <p>{address}</p>
          </div>
          <div className="col-md-4">
            <h5>Ώρες λειτουργίας</h5>
            <p>{workingHours}</p>
          </div>
          <div className="col-md-4">
            <h5>Βρείτε μας</h5>
            <div>
              {socialLinks.facebook && (
                <a
                  href={socialLinks.facebook}
                  className="text-white mx-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook size={30} />
                </a>
              )}
              {socialLinks.twitter && (
                <a
                  href={socialLinks.twitter}
                  className="text-white mx-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter size={30} />
                </a>
              )}
              {socialLinks.instagram && (
                <a
                  href={socialLinks.instagram}
                  className="text-white mx-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram size={30} />
                </a>
              )}
            </div>
          </div>
        </div>
        <hr />
        <p className="text-center mt-3">
          Developed with ❤ by{" "}
          <a
            href="https://github.com/kostaspm"
            target="_blank"
            rel="noreferrer"
          >
            Konstantinos Manolis
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
