import React from "react";

const Intro = ({ brandName, slogan, backgroundImage, logo, colors }) => {
  const sectionStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white",
    padding: "100px 0",
    textAlign: "center",
    backgroundBlendMode: "overlay",
    backgroundColor: colors.footerBackground,
  };

  return (
    <section style={sectionStyle}>
      {logo && (
        <img
          src={require(`../${logo}`)}
          alt={`${brandName} logo`}
          style={{ maxWidth: "200px", marginBottom: "20px", borderRadius: "50%" }}
        />
      )}

      <h1 style={{ fontSize: "3rem" }}>{brandName}</h1>
      <p style={{ fontSize: "1.5rem", marginTop: "10px" }}>{slogan}</p>
    </section>
  );
};

export default Intro;
