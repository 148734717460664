import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/Gallery.css";

const Gallery = ({ images }) => {
  return (
    <section className="p-5" id='gallery'>
      <h2 className="text-center mb-4">Gallery</h2>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        autoPlay={true}
        dynamicHeight={true}
      >
        {images.map((img, index) => (
          <div key={index} className="carousel-image-container">
            <img
              src={require(`../${img}`)}
              alt={`Gallery ${index + 1}`}
              className="carousel-image"
              loading="lazy"
            />
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Gallery;
