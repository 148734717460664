import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';

import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';


const Header = ({ availableComponents, socialLinks, settings }) => {

  const CustomButton = styled(Button)({
    color: settings.colors.text,
  });

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <header className="d-flex justify-content-between align-items-center p-3" style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: settings.colors.background }}>
      <div className="d-flex align-items-center">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiPaper-root': {
                    backgroundColor: settings.colors.background, // Apply background color to the Menu's Paper component
                  }
                }}
              >
                {availableComponents && availableComponents.map((component, index) => (
                  <MenuItem key={component} onClick={handleCloseNavMenu}>
                    <CustomButton sx={{ textAlign: 'center' }} href={`#${component.toLowerCase()}`}>{component}</CustomButton>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {availableComponents &&
                availableComponents.map((component, index) => (
                  <CustomButton key={index} href={`#${component.toLowerCase()}`}>
                    {component}
                  </CustomButton>
                ))}
            </Box>
          </Toolbar>
        </Container>
      </div>
      <div>
        {socialLinks.facebook && (
          <a
            href={socialLinks.facebook}
            className="mx-2"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook size={30} />
          </a>
        )}
        {socialLinks.twitter && (
          <a
            href={socialLinks.twitter}
            className="mx-2"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter size={30} />
          </a>
        )}
        {socialLinks.instagram && (
          <a
            href={socialLinks.instagram}
            className="mx-2"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram size={30} />
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
