import React, { useEffect, useState } from "react";
import Main from "./components/Main";
import generalInformation from "./businessInformation/business.json";

function App() {
  const [customerConfig, setCustomerConfig] = useState({
    colors: {
      background: "#FFFFFF", // Default background color
      text: "#000000", // Default text color
      footerBackground: "#33333",
    },
  });
  const [selectedHostName, setSelectedHostname] = useState("default");

  useEffect(() => {
    const hostname = "spesialistes";
    const config =
      generalInformation[hostname]?.settings || generalInformation["default"];

    // set the title of the page
    document.title = config.title || "Business Name";

    // Merge default values with the loaded configuration
    const mergedConfig = {
      ...customerConfig,
      ...config,
      colors: {
        ...customerConfig.colors,
        ...config.colors,
      },
    };

    setSelectedHostname(hostname);
    setCustomerConfig(mergedConfig);
  }, []); // Add an empty dependency array to run the effect only once

  // Build custom the props for the Main component
  const introProps = {
    ...generalInformation[selectedHostName].intro,
    colors: customerConfig.colors,
  };

  const headerProps = {
    ...generalInformation[selectedHostName].header,
    availableComponents: customerConfig.availableComponents,
    settings: customerConfig
  };

  return (
    <div
      style={{
        backgroundColor: customerConfig.colors.background,
        color: customerConfig.colors.text,
      }}
    >
      <Main
        showHeader={true}
        headerProps={headerProps}
        showIntro={true}
        introProps={introProps}
        showAbout={true}
        aboutProps={generalInformation[selectedHostName].about}
        showServices={true}
        servicesProps={generalInformation[selectedHostName].services}
        showGallery={true}
        galleryProps={generalInformation[selectedHostName].galery}
        showContact={true}
        contactProps={generalInformation[selectedHostName].contact}
        showFooter={true}
        footerProps={generalInformation[selectedHostName].footer}
        footerStyles={{
          backgroundColor: customerConfig.colors.footerBackground,
        }}
      />
    </div>
  );
}

export default App;
