import React from "react";
import "../styles/About.css";

const About = ({ aboutUsText, bioText, images }) => {
  const formattedText = aboutUsText.replace(/\n/g, "<br><br>");

  return (
    <section id='about' className="d-flex p-5 flex-column flex-md-row align-items-center">
      <div className="col-md-6 text-center about-text-container">
        <h2>Γνωρίστε μας</h2>
        <p dangerouslySetInnerHTML={{ __html: formattedText }} />
      </div>
      <div className="col-md-6 d-flex flex-wrap justify-content-center">
        {images.map((img, index) => (
          <div
            key={index}
            className="col-12 col-md-6 p-2 d-flex justify-content-center align-items-center"
            style={{ height: "350px" }}
          >
            <img
              src={require(`../${img}`)}
              alt={`About us ${index + 1}`}
              className="img-fluid"
              loading="lazy"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default About;
